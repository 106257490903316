import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = () => (
  <Layout>
    <SEO title="Not Found" />
    <div className="pure-u-1-1">
      <h1>Not Found</h1>
      <div>
        Can't find what you're looking for? Try going back to the <Link to="/">home page</Link>.
      </div>
    </div>
  </Layout>
)

export default NotFound
